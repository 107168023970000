import { handlePaidContentClickEvent } from '@scm/tag-manager'
import { Icon } from '@scm/ui-component-icon'
import { Image } from '@scm/ui-component-image'
import { Keyword } from '@scm/ui-component-keyword'
import { Link } from '@scm/ui-component-link'
import {
  getContentIconUrl,
  getSummaryFromBody,
} from '@scm/ui-module-content-preview'

import { twoColumnHighlight } from '../constants'
import { ColumnItemProps } from '../type'

export const ColumnItem = ({
  itemData,
  itemType,
  trackGtmClick,
  trackGtmUid,
  trackGtmTitle,
}: ColumnItemProps) => {
  const {
    categoryName,
    categoryUrl,
    articleUrl,
    linkAttrs,
    articleName,
    mobileHeadline,
    articleSummary,
    articleBody,
    contentType,
    listPageUrl,
    thumbnail,
  } = itemData
  const { src = '', alt } = thumbnail
  const icon = getContentIconUrl(contentType)
  const isPrimaryItem = itemType === 'primary'
  const summary = articleSummary || getSummaryFromBody(articleBody)

  return (
    <div
      className={twoColumnHighlight('ColumnItem', { itemType })}
      data-testid="two-column-highlight-column-item"
    >
      {isPrimaryItem && (
        <div className={twoColumnHighlight('ImageContainer')}>
          <div className={twoColumnHighlight('ImageAspectWrapper')}>
            <Link
              to={articleUrl}
              trackGtmClick={trackGtmClick}
              {...linkAttrs}
              trackPaidContentClick={() =>
                handlePaidContentClickEvent(
                  itemData,
                  trackGtmTitle,
                  trackGtmUid,
                )
              }
            >
              <Image
                className={twoColumnHighlight('Image')}
                src={src}
                alt={alt}
                fallback="brand"
              />
            </Link>
          </div>
          <Keyword
            className={twoColumnHighlight('PrimaryItemCategory')}
            title={categoryName}
            url={categoryUrl}
            trackGtmClick={trackGtmClick}
            disabled={categoryName === 'Sponsored Content'}
            variant="standard"
          />
          {icon && (
            <Link
              className={twoColumnHighlight('IconWrapper')}
              to={listPageUrl}
              trackGtmClick={trackGtmClick}
              title={`Link to all ${contentType.toLowerCase()}`}
              aria-label="Redirect to All page"
              {...linkAttrs}
              data-testid="content-preview-icon-link"
            >
              <Icon icon={icon} className={twoColumnHighlight('Icon')} />
            </Link>
          )}
        </div>
      )}
      {!isPrimaryItem && (
        <Keyword
          className={twoColumnHighlight('SupplementalCategory')}
          title={categoryName}
          url={categoryUrl}
          trackGtmClick={trackGtmClick}
          disabled={categoryName === 'Sponsored Content'}
          variant="alternate"
        />
      )}
      <div className={twoColumnHighlight('SupplementalItemTitleWrapper')}>
        <Link
          to={articleUrl}
          trackGtmClick={trackGtmClick}
          className={twoColumnHighlight('ItemTitle', {
            displayOption: 'default',
            itemType,
          })}
          data-testid="column-item-default-title"
          trackPaidContentClick={() =>
            handlePaidContentClickEvent(itemData, trackGtmTitle, trackGtmUid)
          }
          {...linkAttrs}
        >
          {articleName}
        </Link>
        <Link
          to={articleUrl}
          trackGtmClick={trackGtmClick}
          className={twoColumnHighlight('ItemTitle', {
            displayOption: 'mobile',
            itemType,
          })}
          data-testid="column-item-mobile-title"
          trackPaidContentClick={() =>
            handlePaidContentClickEvent(itemData, trackGtmTitle, trackGtmUid)
          }
          {...linkAttrs}
        >
          {mobileHeadline || articleName}
        </Link>
      </div>
      <p
        data-testid="column-item-summary"
        className={twoColumnHighlight('ItemSummary', { itemType })}
      >
        {summary}
      </p>
    </div>
  )
}
