import { Ads } from '@scm/ads'
import { useGtmClick, usePaidContentListLoaded } from '@scm/tag-manager'
import { Icon } from '@scm/ui-component-icon'
import { Link } from '@scm/ui-component-link'

import { Cards } from './components/cards'
import { MODULE_UID, microList } from './constants'
import { MicroListProps } from './type'

export const MicroList = (props: MicroListProps) => {
  const {
    title,
    ctaButton,
    content,
    moduleLocation,
    contentDisplayOptions,
    adPos,
  } = props
  const { trackGtmClick } = useGtmClick(title, moduleLocation, MODULE_UID)
  const { buttonText, buttonUrl } = ctaButton || {}
  const cardsContent =
    contentDisplayOptions === '2-entries'
      ? content.slice(0, 2)
      : content.slice(0, 3)

  usePaidContentListLoaded(title, MODULE_UID, content)

  return (
    <div data-module="micro-list" className={microList()}>
      <div data-testid="list-wrapper" className={microList('ListWrapper')}>
        <div className={microList('Header')}>
          <h3 className={microList('Title')} data-testid="micro-list-title">
            {title}
          </h3>
          {buttonUrl && buttonText && (
            <Link
              className={microList('ListLink')}
              to={buttonUrl}
              trackGtmClick={trackGtmClick}
              target={buttonUrl.startsWith('http') ? '_blank' : '_self'}
            >
              <span
                className={microList('ListLinkText')}
                data-testid="micro-list-breadcrumbs-link-text"
              >
                {buttonText}
              </span>
              <Icon icon="ChevronRight" className={microList('ListLinkIcon')} />
            </Link>
          )}
        </div>
        <Cards
          content={cardsContent}
          trackGtmClick={trackGtmClick}
          trackGtmUid={MODULE_UID}
          trackGtmTitle={title}
        />
      </div>
      {adPos && (
        <div className={microList('AdWrapper')}>
          <Ads.Ad
            pos={adPos}
            className={microList('Ad')}
            sizes={[[300, 250]]}
            responsiveSizeMapping={[
              {
                width: 0,
                height: 0,
                sizes: [[300, 250]],
              },
              {
                width: 480,
                height: 200,
                sizes: [[300, 250]],
              },
            ]}
          />
        </div>
      )}
    </div>
  )
}
