import { Fragment } from 'react'

import { useGtmClick, usePaidContentListLoaded } from '@scm/tag-manager'

import { Column } from './components'
import {
  MODULE_UID,
  ONE_SUPPLEMENT,
  TWO_SUPPLEMENTS,
  twoColumnHighlight,
} from './constants'
import { TwoColumnHighlightProps } from './type'

export const TwoColumnHighlight = ({
  title,
  moduleDisplayOptions,
  data,
  moduleLocation,
}: TwoColumnHighlightProps) => {
  const { trackGtmClick } = useGtmClick(title, moduleLocation, MODULE_UID)
  const numberOfSupplements =
    moduleDisplayOptions === 'twoEntries' ? ONE_SUPPLEMENT : TWO_SUPPLEMENTS

  const allContents = [...data[0].contents, ...data[1].contents]

  usePaidContentListLoaded(title, MODULE_UID, allContents)
  return (
    <div data-module="two-column-highlight" className={twoColumnHighlight()}>
      {data.map((columnData, columnIndex) => (
        <Fragment key={`column-${columnIndex}`}>
          <Column
            columnData={columnData}
            numberOfSupplements={numberOfSupplements}
            trackGtmClick={trackGtmClick}
            trackGtmUid={MODULE_UID}
            trackGtmTitle={title}
          />
        </Fragment>
      ))}
    </div>
  )
}
