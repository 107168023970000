import { handlePaidContentClickEvent } from '@scm/tag-manager'
import { Icon } from '@scm/ui-component-icon'
import { Image } from '@scm/ui-component-image'
import { Keyword } from '@scm/ui-component-keyword'
import { Link } from '@scm/ui-component-link'
import { getContentIconUrl } from '@scm/ui-module-content-preview'

import { microList } from '../constants'
import { CardProps } from '../type'

export const Card = ({
  cardData,
  trackGtmClick,
  trackGtmUid,
  trackGtmTitle,
}: CardProps) => {
  const {
    categoryName,
    categoryUrl,
    articleUrl,
    linkAttrs,
    articleName,
    mobileHeadline,
    articleSummary,
    contentType,
    listPageUrl,
    thumbnail,
  } = cardData
  const { src = '', alt } = thumbnail
  const icon = contentType !== 'Event' ? getContentIconUrl(contentType) : null

  return (
    <div data-testid="card" className={microList('Card')}>
      <div
        data-testid="image-container"
        className={microList('ImageContainer')}
      >
        <div data-testid="image" className={microList('ImageWrapper')}>
          <Link
            to={articleUrl}
            trackGtmClick={trackGtmClick}
            {...linkAttrs}
            trackPaidContentClick={() =>
              handlePaidContentClickEvent(cardData, trackGtmTitle, trackGtmUid)
            }
          >
            <Image
              className={microList('Image')}
              src={src}
              alt={alt}
              fallback="brand"
              width={150}
              height={120}
            />
          </Link>
        </div>
        {icon && (
          <Link
            className={microList('IconWrapper')}
            to={listPageUrl}
            trackGtmClick={trackGtmClick}
            title={`Link to all ${contentType.toLowerCase()}`}
            aria-label="Redirect to All page"
            {...linkAttrs}
            data-testid="content-preview-icon-link"
          >
            <Icon icon={icon} className={microList('Icon')} />
          </Link>
        )}
      </div>
      <div className={microList('CardWrapper')}>
        <Keyword
          className={microList('CardKeyword')}
          title={categoryName}
          url={categoryUrl}
          trackGtmClick={trackGtmClick}
          disabled={categoryName === 'Sponsored Content'}
          variant="alternate"
        />

        <Link
          to={articleUrl}
          trackGtmClick={trackGtmClick}
          className={microList('CardTitle', {
            displayOption: 'default',
          })}
          data-testid="card-title"
          {...linkAttrs}
          trackPaidContentClick={() =>
            handlePaidContentClickEvent(cardData, trackGtmTitle, trackGtmUid)
          }
        >
          {articleName}
        </Link>
        <Link
          to={articleUrl}
          trackGtmClick={trackGtmClick}
          className={microList('CardTitle', {
            displayOption: 'mobile',
          })}
          data-testid="card-mobile-title"
          {...linkAttrs}
          trackPaidContentClick={() =>
            handlePaidContentClickEvent(cardData, trackGtmTitle, trackGtmUid)
          }
        >
          {mobileHeadline || articleName}
        </Link>
        <p data-testid="card-summary" className={microList('CardSummary')}>
          {articleSummary}
        </p>
      </div>
    </div>
  )
}
