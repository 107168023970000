import { microList } from '../constants'
import { CardsProps } from '../type'
import { Card } from './card'

export const Cards = ({
  content,
  trackGtmClick,
  trackGtmUid,
  trackGtmTitle,
}: CardsProps) => {
  const lastIndex = content ? content.length - 1 : 0

  return (
    <div data-testid="cards" className={microList('CardsContainer')}>
      {content.map((item, index) => (
        <div
          key={index}
          className={microList('CardsWrapper', {
            first: index === 0,
            last: index === lastIndex,
          })}
        >
          <Card
            cardData={item}
            trackGtmClick={trackGtmClick}
            trackGtmUid={trackGtmUid}
            trackGtmTitle={trackGtmTitle}
          />
        </div>
      ))}
    </div>
  )
}
