import { MouseEvent } from 'react'

import { EventType, GTM } from '@scm/tag-manager'

import { TopFeaturedAndRecentContent } from './components/topFeaturedAndRecentContent'
import { TopFeaturedDefault } from './components/topFeaturedDefault'
import { TopFeaturedWithAds } from './components/topFeaturedWithAds'
import { TopFeaturedModuleProps } from './type'

const MODULE_UID = 'module_featured'

export const TopFeatured = (props: TopFeaturedModuleProps) => {
  const url = GTM.utils.getCurrentUrl()
  const sendEvent = GTM.hooks.useSendEvent()

  const trackGtmClick = (href: string, e?: MouseEvent<HTMLAnchorElement>) => {
    e?.stopPropagation()
    if (!url) return
    const payload = {
      name: props.title,
      location: props.moduleLocation,
      section: MODULE_UID,
      referring_page: url.pathname,
      destination_page: GTM.utils.prepareDestinationPath(href, url),
    }
    sendEvent({
      data: payload,
      event: EventType.NAVIGATION,
      _clear: true,
    })
  }

  if (props.variant === 'recent') {
    return (
      <TopFeaturedAndRecentContent
        {...props}
        trackGtmClick={trackGtmClick}
        trackGtmUid={MODULE_UID}
      />
    )
  }
  if (props.variant === 'featuredWithAds') {
    return (
      <TopFeaturedWithAds
        {...props}
        trackGtmClick={trackGtmClick}
        trackGtmUid={MODULE_UID}
      />
    )
  }

  return (
    <TopFeaturedDefault
      {...props}
      trackGtmClick={trackGtmClick}
      trackGtmUid={MODULE_UID}
    />
  )
}
