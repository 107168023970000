import { ContentCards } from '@scm/ui-module-content-cards'
import { ContentRibbon } from '@scm/ui-module-content-ribbon'
import { ContentSpotlight } from '@scm/ui-module-content-spotlight'
import { Embed } from '@scm/ui-module-embed'
import { DataTable } from '@scm/ui-module-data-table'
import { EventFeature } from '@scm/ui-module-event-feature'
import { FeatureSeries } from '@scm/ui-module-feature-series'
import { FeatureSeriesV2 } from '@scm/ui-module-feature-series-v2'
import { FeaturedContentHub } from '@scm/ui-module-featured-content-hub'
import { Header } from '@scm/ui-module-header'
import { HighlightContents } from '@scm/ui-module-highlight-contents'
import { LatestFeatured } from '@scm/ui-module-latest-featured'
import { LinkedImage } from '@scm/ui-module-linked-image'
import { MicroList } from '@scm/ui-module-micro-list'
import { NewsletterSignupPromo } from '@scm/ui-module-newsletter-signup-promo'
import { Panels } from '@scm/ui-module-panels'
import { PublicationPromo } from '@scm/ui-module-publication-promo'
import { PullQuote } from '@scm/ui-module-pull-quote'
import { Resources, ResourcesVariant } from '@scm/ui-module-resources'
import { TextBox } from '@scm/ui-module-text-box'
import { TopFeatured } from '@scm/ui-module-top-featured'
import { TwoColumnHighlight } from '@scm/ui-module-two-column-highlight'
import { VideoPlaylist } from '@scm/ui-module-video-playlist'
import { VideoSpotlight } from '@scm/ui-module-video-spotlight'
import { ModuleSectionProps } from './type'
import { WeatherHomepage } from '@scm/ui-template-weather-homepage'

export const ModuleSection = ({
  module,
  socialShare,
  isOnHomepage = false,
  homePageNewsletterClassName,
}: ModuleSectionProps) => {
  if (!module) {
    return null
  }
  const { type, data } = module

  switch (type) {
    case 'module_top_featured':
      return (
        <TopFeatured
          {...(isOnHomepage ? { ...data, moduleLocation: 'homepage' } : data)}
        />
      )

    case 'module_featured_series_content':
      return (
        <FeatureSeries
          {...(isOnHomepage ? { ...data, moduleLocation: 'homepage' } : data)}
        />
      )

    case 'module_featured_series_content_v2':
      return (
        <FeatureSeriesV2
          {...(isOnHomepage ? { ...data, moduleLocation: 'homepage' } : data)}
        />
      )

    case 'module_featured_content_hub':
      return (
        <FeaturedContentHub
          {...(isOnHomepage ? { ...data, moduleLocation: 'homepage' } : data)}
        />
      )

    case 'module_content_spotlight':
      return (
        <ContentSpotlight
          {...(isOnHomepage ? { ...data, moduleLocation: 'homepage' } : data)}
        />
      )

    case 'module_video_spotlight':
      return (
        <VideoSpotlight
          {...(isOnHomepage ? { ...data, moduleLocation: 'homepage' } : data)}
        />
      )

    case 'module_event_promo':
      return (
        <EventFeature
          {...(isOnHomepage ? { ...data, moduleLocation: 'homepage' } : data)}
        />
      )

    case 'module_contents_card':
      return (
        <ContentCards
          {...(isOnHomepage ? { ...data, moduleLocation: 'homepage' } : data)}
        />
      )

    case 'module_content_ribbon':
      return (
        <ContentRibbon
          {...(isOnHomepage ? { ...data, moduleLocation: 'homepage' } : data)}
        />
      )

    case 'module_linked_image':
      return (
        <LinkedImage
          {...(isOnHomepage ? { ...data, moduleLocation: 'homepage' } : data)}
        />
      )

    case 'module_micro_list':
      return (
        <MicroList
          {...(isOnHomepage ? { ...data, moduleLocation: 'homepage' } : data)}
        />
      )

    case 'module_resources':
      return (
        <Resources
          {...(isOnHomepage
            ? {
                ...data,
                variant: ResourcesVariant.homepage,
                moduleLocation: 'homepage',
              }
            : data)}
        />
      )

    case 'module_newsletter_promo':
      return (
        <NewsletterSignupPromo
          {...(isOnHomepage
            ? {
                ...data,
                moduleLocation: 'homepage',
                isOnHomepage,
                className: homePageNewsletterClassName,
              }
            : data)}
        />
      )

    case 'module_three_column_highlight':
      return (
        <HighlightContents
          {...(isOnHomepage
            ? {
                ...data,
                moduleLocation: 'homepage',
                isNativeAdEnabled: module?.adConfig?.nativeAdOption,
              }
            : data)}
        />
      )

    case 'module_two_column_highlight':
      return <TwoColumnHighlight {...data} />

    case 'module_latest_and_featured':
      return (
        <LatestFeatured
          {...(isOnHomepage ? { ...data, moduleLocation: 'homepage' } : data)}
        />
      )

    case 'module_weather_and_commodity':
      return isOnHomepage ? <WeatherHomepage {...data} /> : null

    case 'module_publication_promo':
      return <PublicationPromo {...data} />

    case 'module_panels':
      return (
        <Panels
          {...(isOnHomepage ? { ...data, moduleLocation: 'homepage' } : data)}
        />
      )

    case 'module_top_featured_and_latest_news':
      //TODO: TOP FEATURED & LATEST NEWS MODULE
      return null

    case 'module_video_playlist':
      return (
        <VideoPlaylist
          {...(isOnHomepage ? { ...data, moduleLocation: 'homepage' } : data)}
        />
      )

    case 'module_embed':
      return (
        <Embed
          {...(isOnHomepage ? { ...data, moduleLocation: 'homepage' } : data)}
        />
      )

    case 'module_text_box':
      return (
        <TextBox
          {...(isOnHomepage ? { ...data, moduleLocation: 'homepage' } : data)}
        />
      )

    case 'module_pull_quote':
      return (
        <PullQuote
          {...(isOnHomepage ? { ...data, moduleLocation: 'homepage' } : data)}
        />
      )

    case 'module_data_table':
      return (
        <DataTable
          {...(isOnHomepage ? { ...data, moduleLocation: 'homepage' } : data)}
        />
      )

    case 'module_header':
      return (
        <Header
          {...(isOnHomepage ? { ...data, moduleLocation: 'homepage' } : data)}
          socialShare={socialShare}
        />
      )

    default:
      return null
  }
}
