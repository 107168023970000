import { Content } from '@scm/ui-module-content'
import { EmbedProps } from './type'

export const Embed = ({ embedCodeJson }: EmbedProps) => {
  return (
    <div data-module="embed">
      <Content content={embedCodeJson} />
    </div>
  )
}
