import { Fragment } from 'react'

import { Button } from '@scm/ui-component-button'

import { twoColumnHighlight } from '../constants'
import { ColumnProps } from '../type'
import { ColumnItem } from './columnItem'

export const Column = ({
  columnData,
  numberOfSupplements,
  trackGtmClick,
  trackGtmUid,
  trackGtmTitle,
}: ColumnProps) => {
  const { columnTitle, contents, ctaButton } = columnData
  const { buttonTitle, buttonUrl } = ctaButton || {}

  const supplementItems = contents.slice(1, numberOfSupplements + 1)

  const isExternalUrl = buttonUrl?.startsWith('http')
  const targetUrl = isExternalUrl ? '_blank' : '_self'

  return (
    <div
      className={twoColumnHighlight('Column')}
      data-testid="two-column-highlight-column"
    >
      {columnTitle && (
        <p
          className={twoColumnHighlight('ColumnTitle')}
          data-testid="column-title"
        >
          {columnTitle}
        </p>
      )}

      <ColumnItem
        itemData={contents[0]}
        itemType="primary"
        trackGtmClick={trackGtmClick}
        trackGtmUid={trackGtmUid}
        trackGtmTitle={trackGtmTitle}
      />

      <div className={twoColumnHighlight('SupplementalItemsList')}>
        {supplementItems.map((item, index) => (
          <Fragment key={index}>
            <ColumnItem
              itemData={item}
              itemType="supplemental"
              trackGtmClick={trackGtmClick}
              trackGtmUid={trackGtmUid}
              trackGtmTitle={trackGtmTitle}
            />
          </Fragment>
        ))}
      </div>

      {buttonTitle && buttonUrl && (
        <div className={twoColumnHighlight('ColumnButtonWrapper')}>
          <Button
            className={twoColumnHighlight('ColumnButton')}
            variant="text-link"
            size="medium"
            url={buttonUrl}
            rightIcon="ChevronRight"
            trackGtmClick={trackGtmClick}
            buttonLikeAnchor={isExternalUrl}
            target={targetUrl}
          >
            {buttonTitle}
          </Button>
        </div>
      )}
    </div>
  )
}
